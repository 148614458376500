import React, { useState } from "react"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "@emotion/styled"
import { callToActionStyle } from "../styles"
import Splash from "../components/splash"
import { useStaticQuery, graphql } from "gatsby"

const Container = styled.div`
  display: grid;
  grid-template-columns: 40% 50%;
  grid-gap: 0% 10%;

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    display: block;
  }
`

const ContactPage = ({ splashImage }) => {
  const [status, setStatus] = useState("")
  const submitForm = ev => {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setStatus("SUCCESS")
      } else {
        setStatus("ERROR")
      }
    }
    xhr.send(data)
  }
  return (
    <Layout>
      <SEO title="Contact Us" />
      <Splash title="Contact Us" subtitle="Essex Capital Partners">
        <Img style={{ minHeight: "150px" }} fluid={splashImage} />
      </Splash>
      <br />
      <Container
      >
        <div>
          <p>635 Madison Avenue New York, NY 10022</p>
          <br />
          <br />
          <p>
            T: <a href="tel:+12128880800">212 888 0800</a>
          </p>
          <p>
            F: <a href="tel:+12128880220">212 888 0220</a>
          </p>
          <br />
          <p>
            <a href="mailto:info@essexcapital.com">info@essexcapital.com</a>
          </p>
          <br />
          <br />
          <div
            style={{
              width: "100%",
              position: "relative",
              paddingRight: "1rem",
            }}
          >
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3021.9226827827224!2d-73.9731881846808!3d40.76372524238649!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258effaef7c91%3A0xe398090b1af1b254!2s635%20Madison%20Ave%2C%20New%20York%2C%20NY%2010022!5e0!3m2!1sen!2sus!4v1584891780979!5m2!1sen!2sus"
              frameborder="0"
              style={{ border: 0, width: "100%", height: "100%" }}
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></iframe>
          </div>
        </div>
        <div>
          <Form
            onSubmit={submitForm}
            action="https://formspree.io/mleppyga"
            method="POST"
          >
            <formset style={{ display: "block" }}>
              <label htmlFor="name">Name</label>
              <input name="name" id="name" type="text" />
            </formset>
            <formset style={{ display: "block" }}>
              <label htmlFor="email">E-mail</label>
              <input name="email" id="email" type="email" />
            </formset>
            <formset style={{ display: "block" }}>
              <label htmlFor="phone">Phone</label>
              <input name="phone" id="phone" type="text" />
            </formset>
            <formset style={{ display: "block" }}>
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" />
            </formset>
            {status === "ERROR" && (
              <p>
                Ooops! There was an error. Please try again or contact us
                directly.
              </p>
            )}
            {status === "SUCCESS" ? (
              <p>Your message was sent successfully.</p>
            ) : (
              <button css={theme => callToActionStyle(theme)} type="submit">
                {" "}
                Submit{" "}
              </button>
            )}
          </Form>
        </div>
      </Container>
    </Layout>
  )
}

const Form = styled.form`
  display: block;
  formset {
    label {
      display: block;
    }
    display: block;
    input {
      padding: 0.3rem 1rem;
      width: 400px;
      margin-bottom: 0.3rem;
    }
    textarea {
      width: 400px;
    }

    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      input, textarea {
        width: 100%;
      }
    }
  }

`

export default props => {
  const data = useStaticQuery(graphql`
    query {
      splashImage: file(relativePath: { eq: "about/banner.png" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const splashImage = data.splashImage.childImageSharp.fluid
  return <ContactPage {...props} splashImage={splashImage} />
}
